

import {
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    InputAdornment,
    Paper,
    TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../utils/Api";
import Listtable from "../../utils/ListTable";
import SearchIcon from "@mui/icons-material/Search";
import MedicalFacultyModal from "./MedicalFacultyModal";
import { useTranslation } from "react-i18next";

const MedicalFacultyList = () => {
    const { t } = useTranslation();
    const [count, setCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [medicalFaculties, setMedicalFaculties] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [backDropOpen, setBackDropOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState({});
    const [isEditDoctor, setIsEditDoctor] = useState(false)
    const tableHeader = [
        "Name",
        "Type",
        "Fee",
        "Status",
    ];
    const keysName = [
        "name",
        "type",
        "fees",
        "active",
    ];

    useEffect(() => {
        getMedicalFaculties();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage, pageNumber]);

    useEffect(() => {
        getMedicalFaculties();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getMedicalFaculties = (e) => {
        try {
            setBackDropOpen(true);
            const payload = {
                ...(e &&
                    e.target.value && {
                    search: e.target.value,
                }),
                limit: rowsPerPage,
                page: pageNumber + 1,
            };

            api
                .post("/hospital/medicalfFacultyList", payload)
                .then((response) => {
                    setMedicalFaculties(response?.data?.data?.medicalFacultyList);
                    setCount(response?.data?.data?.totalCount);
                    setBackDropOpen(false);
                })
                .catch((err) => {
                    setBackDropOpen(false);
                    if (err?.response?.data?.message) {
                        toast.error(`Error :${err?.response?.data?.message}`);
                    } else {
                        toast.error("Medical Facility Not Found");
                    }
                });
        } catch (error) {
            setBackDropOpen(false);
            toast.error("Medical Facility list error");
        }
    };

    const handleChangePage = (event, newPage) => {
        setPageNumber(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPageNumber(0);
    };

    const handleModal = (isEdit, e = {}) => {
        setData(e);
        setIsEditDoctor(isEdit)
        setOpenModal(true);
    };

    const handleModalClose = (buttonText = "save") => {
        setData({});
        if (buttonText !== "close") {
            getMedicalFaculties();
        }
        setOpenModal(false);
    };


    return (
        <>
            <Grid container alignItems={"center"}>
                <Grid item sm={12} md={12} lg={12} justifyContent="center" padding={2}>
                    <Paper sx={{ p: 2 }}>
                        <Backdrop
                            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={backDropOpen}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
                            <Grid item sm={12} xs={12} md={2} >
                                <TextField
                                    size="small"
                                    type={"text"}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{ left: 0 }}
                                    variant="outlined"
                                    placeholder="Search Name"
                                    onKeyPress={(e) => {
                                        e.charCode === 13 && getMedicalFaculties(e);
                                    }}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12} md={2} >
                                <Button
                                    sx={{ p: 1 }}
                                    variant="contained"
                                    fullWidth
                                    onClick={() => handleModal(false)}>
                                    {t('CREATE_MEDIAL_FAC')}
                                </Button>
                            </Grid>
                        </Stack>
                    </Paper>
                    <Listtable
                        tableheader={tableHeader}
                        tablebody={medicalFaculties}
                        rowsPerPage={rowsPerPage}
                        page={pageNumber}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        totalCount={count}
                        dataKeyName={keysName}
                        editHandle={(e) => handleModal(true, e)}
                        tag="hospitals"
                    />
                </Grid>
            </Grid>

            {openModal && (
                <MedicalFacultyModal
                    open={openModal}
                    onClose={handleModalClose}
                    medicalFaculties={data}
                    isEditDoctor={isEditDoctor}
                />
            )}
        </>
    );
};

export default MedicalFacultyList;
