import { Edit } from "@mui/icons-material";
import DoneIcon from '@mui/icons-material/Done';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import "./Table.css";
import { useNavigate } from "react-router-dom";
import ListAltIcon from '@mui/icons-material/ListAlt';

const Listtable = ({
  tableheader,
  tablebody = [],
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  totalCount,
  dataKeyName,
  editHandle,
  tag,
  handleAvailabilityModal
}) => {
  const navigate = useNavigate();

  return (
    <>
      <TableContainer className="container" component={Paper}>
        <Table aria-label="sticky table">
          <TableHead  >
            <TableRow >
              {tableheader?.map((thead) => (
                <TableCell style={{ fontWeight: "bold" }} key={thead.id}>{thead}</TableCell>
              ))}
              {tag !== "patients" && <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>}
              {tag === "hospitals" && <TableCell style={{ fontWeight: "bold" }} >Doctors</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {tablebody?.length === 0 && (
              <TableRow hover role="checkbox" key="data">
                <TableCell colSpan={tableheader?.length} align={"center"}>
                  <h2>No Data Available</h2>
                </TableCell>
              </TableRow>
            )}
            {tablebody?.length > 0 &&
              tablebody.map((tbody) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={tbody._id}>
                    {dataKeyName.map((value, index) =>
                      value === "doctorStatus" ? (
                        <TableCell key={index}
                          style={{
                            color:
                              tbody[value] === "ACTIVE" ? "success" : "warning",
                          }}>
                          {tbody[value]}
                        </TableCell>
                      ) : (
                        <TableCell key={index}>{tbody[value]}</TableCell>
                      )
                    )}
                    {
                      tag === 'doctorsrequest' && (
                        <TableCell>
                          <IconButton onClick={() => editHandle(tbody)}>
                            <DoneIcon color="primary" fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )
                    }
                    {tag !== "patients" && tag !== 'doctorsrequest' && (
                      <TableCell>
                        <IconButton onClick={() => editHandle(tbody)}>
                          <Edit color="primary" fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                    {tag === "hospitals" && (
                      <TableCell>
                        <IconButton onClick={() => navigate("/medicalFaculty/doctors", { state: { hospitalId: tbody['_id'] } })}>
                          <ListAltIcon color="primary" fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                    {tag === "hospitalDoctors" && (
                      <TableCell>
                        <IconButton onClick={() => handleAvailabilityModal(tbody._id)}>
                          <ListAltIcon color="primary" fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer >

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Listtable;
