import {
  Backdrop,
  Chip,
  CircularProgress,
  Grid,
  InputAdornment,
  List,
  ListItem,
  Paper,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../utils/Api";
import Listtable from "../../utils/ListTable";
import ModalScreen from "../Modal";
import SearchIcon from "@mui/icons-material/Search";
import { Done } from "@mui/icons-material";

const PatientList = () => {
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [patientData, setPatientData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [isAbha, setIsAbha] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});
  const [dist, setDist] = useState('All');
  const [distList, setDistList] = useState(['All']);
  

  const tableHeader = ["Name", "Phone", "Email", "Gender", "District", "Health Id"];
  const keysName = ["name", "phone", "email", "gender", "district", "healthId"];

  const PatientsList = (e) => {
    try {
      setBackDropOpen(true);
      const PayLoadDoctorList = {
        ...(e &&
          e.target.value && {
            search: e.target.value,
          }),
        limit: rowsPerPage,
        page: pageNumber + 1,
        isAbha: isAbha,
        ...(dist && {distFilter: dist})
      };

      api
        .post("/admin/patients-list", PayLoadDoctorList)
        .then((response) => {
          setCount(response?.data?.data?.totalCount);
          setPatientData(response?.data?.data?.patientList);
          setDistList(['All', ...(response?.data?.data?.distinct || [])])
          setBackDropOpen(false);
        })
        .catch((err) => {
          setBackDropOpen(false);
          if (err?.response?.data?.message) {
            toast.error(`Error :${err?.response?.data?.message}`);
          } else {
          }
        });
    } catch (error) {
      setBackDropOpen(false);
      toast.error("Doctor list error");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPageNumber(0);
  };

  const handleModal = (e) => {
    setData(e);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setData({});
    setOpenModal(false);
  };

  const handleChange = (event) =>{
    setDist(event.target.value);
  }

  useEffect(() => {
    PatientsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, pageNumber, isAbha, dist]);

  useEffect(() => {
    PatientsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Grid container alignItems={"center"}>
        <Grid item sm={12} md={12} lg={12} justifyContent="center" padding={2}>
          <Paper sx={{ p: 2 }}>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={backDropOpen}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <List sx={{ display: "flex", justifyContent: "space-around" }}>
                <ListItem>
                  {isAbha === undefined ? (
                    <Chip
                      label="All"
                      onClick={() => setIsAbha(undefined)}
                      color={"primary"}
                      icon={<Done />}
                    />
                  ) : (
                    <Chip label="All" onClick={() => setIsAbha(undefined)} />
                  )}
                </ListItem>
                <ListItem>
                  {isAbha === true ? (
                    <Chip
                      label="With ABHA"
                      onClick={() => setIsAbha(true)}
                      color="primary"
                      icon={<Done />}
                    />
                  ) : (
                    <Chip label="With ABHA" onClick={() => setIsAbha(true)} />
                  )}
                </ListItem>
                <ListItem>
                  {isAbha === false ? (
                    <Chip
                      label="Without ABHA"
                      onClick={() => setIsAbha(false)}
                      color="primary"
                      icon={<Done />}
                    />
                  ) : (
                    <Chip
                      label="Without ABHA"
                      onClick={() => setIsAbha(false)}
                    />
                  )}
                </ListItem>
              </List>
              <div>
              <FormControl>
                <InputLabel id="dist-label">Dist</InputLabel>
                <Select
                  labelId="dist-label"
                  id="demo-simple-select"
                  value={dist}
                  label="Dist"
                  variant="outlined"
                  size="small"
                  style={{ marginRight: '10px' }}
                  onChange={handleChange}
                >
                  ({
                    distList && distList.length > 0 && distList.map(item=> <MenuItem value={item}>{item}</MenuItem>)
                  })
                  
                </Select>
              </FormControl>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                size="small"
                sx={{ justifyContent: "right !important" }}
                type={"text"}
                variant="outlined"
                placeholder="Search Name"
                onKeyPress={(e) => {
                  e.charCode === 13 && PatientsList(e);
                }}
              />
              </div>
            </div>
          </Paper>

          <Listtable
            tableheader={tableHeader}
            tablebody={patientData}
            rowsPerPage={rowsPerPage}
            page={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalCount={count}
            dataKeyName={keysName}
            editHandle={handleModal}
            tag="patients"
          />
        </Grid>
      </Grid>

      {openModal && (
        <ModalScreen
          open={openModal}
          onClose={handleModalClose}
          data={data}
          type="patients"
        />
      )}
    </>
  );
};

export default PatientList;
