import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  List,
  ListItem,
  Chip
} from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../utils/Api";
import Listtable from "../../utils/ListTable";
import SearchIcon from "@mui/icons-material/Search";
import DoctorModal from "./DoctorModal";
import { useTranslation } from "react-i18next";
import { Done } from "@mui/icons-material";

const DoctorList = () => {
  const { t } = useTranslation();
  const [isAbha, setIsAbha] = useState(undefined);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [doctorData, setDoctorData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});
  const [isEditDoctor, setIsEditDoctor] = useState(false)
  const tableHeader = [
    "Registration Number",
    "Name",
    "Phone",
    "Email",
    "Type",
    "Status",
  ];
  const tableHeader2 = [
    "Name",
    "Hospital Name",
    "Phone",
    "Email",
    "Type",
    "Status",
  ];
  const keysName = [
    "registrationNumber",
    "name",
    "phone",
    "email",
    "role",
    "doctorStatus",
  ];
  const keysName2 = [
    "name",
    "hospitalName",
    "phone",
    "email",
    "role",
    "doctorStatus",
  ];

  const DoctorList = (e) => {
    try {
      setBackDropOpen(true);
      const PayLoadDoctorList = {
        ...(e &&
          e.target.value && {
          search: e.target.value,
        }),
        limit: rowsPerPage,
        page: pageNumber + 1,
        doctorRole: isAbha
      };

      api
        .post("/admin/doctors-list", PayLoadDoctorList)
        .then((response) => {
          const list = response?.data?.data?.doctorList?.map(x=>{
            return {
              ...x,
              role: x?.role?.type || '-'
            }
          })
          setDoctorData(list || []);
          setCount(response?.data?.data?.totalCount);
          setBackDropOpen(false);
        })
        .catch((err) => {
          setBackDropOpen(false);
          if (err?.response?.data?.message) {
            toast.error(`Error :${err?.response?.data?.message}`);
          } else {
            toast.error("Doctor Not Found");
          }
        });
    } catch (error) {
      setBackDropOpen(false);
      toast.error("Doctor list error");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPageNumber(0);
  };

  const handleModal = (isEdit, e = {}) => {
    setData(e);
    setIsEditDoctor(isEdit)
    setOpenModal(true);
  };

  const handleModalClose = (buttonText = "save") => {
    setData({});
    if (buttonText !== "close") {
      DoctorList();
    }
    setOpenModal(false);
  };

  useEffect(() => {
    DoctorList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, pageNumber, isAbha]);

  useEffect(() => {
    DoctorList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Grid container alignItems={"center"}>
        <Grid item sm={12} md={12} lg={12} justifyContent="center" padding={2}>
          <Paper sx={{ p: 2 }}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={backDropOpen}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Stack direction="row" spacing={2}>
              <Grid item sm={6} xs={6} md={6} style={{justifyContent: "flex-start", display: 'flex', alignItems: 'center'}}>
                <List sx={{ display: "flex", justifyContent: "space-around" }}>
                  <ListItem>
                    {isAbha === undefined ? (
                      <Chip
                        label="All"
                        onClick={() => setIsAbha(undefined)}
                        color={"primary"}
                        icon={<Done />}
                      />
                    ) : (
                      <Chip label="All" onClick={() => setIsAbha(undefined)} />
                    )}
                  </ListItem>
                  <ListItem>
                    {isAbha === 'DOCTOR' ? (
                      <Chip
                        label="DOCTOR"
                        onClick={() => setIsAbha('DOCTOR')}
                        color="primary"
                        icon={<Done />}
                      />
                    ) : (
                      <Chip label="DOCTOR" onClick={() => setIsAbha('DOCTOR')} />
                    )}
                  </ListItem>
                  <ListItem>
                    {isAbha === 'RECEPTION' ? (
                      <Chip
                        label="RECEPTION"
                        onClick={() => setIsAbha('RECEPTION')}
                        color="primary"
                        icon={<Done />}
                      />
                    ) : (
                      <Chip
                        label="RECEPTION"
                        onClick={() => setIsAbha('RECEPTION')}
                      />
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item sm={4} xs={4} md={4} justifyContent={'flex-end'} display={'flex'} alignItems={'center'} >
                <TextField
                  size="small"
                  type={"text"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ left: 0 }}
                  variant="outlined"
                  placeholder="Search Name"
                  onKeyPress={(e) => {
                    e.charCode === 13 && DoctorList(e);
                  }}
                />
              </Grid>
              <Grid item sm={2} xs={2} md={2} justifyContent={'flex-end'} display={'flex'} alignItems={'center'}>
                <Button
                sx={{p:1}}
                  variant="contained"
                  fullWidth
                  onClick={() => handleModal(false)}>
                  {t('CREATE_DOCTOR')}
                </Button>
              </Grid>
            </Stack>
          </Paper>
          <Listtable
            tableheader={isAbha === 'RECEPTION' ? tableHeader2 : tableHeader}
            tablebody={doctorData}
            rowsPerPage={rowsPerPage}
            page={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalCount={count}
            dataKeyName={isAbha === 'RECEPTION' ? keysName2 : keysName}
            editHandle={(e) => handleModal(true, e)}
            tag="doctors"
          />
        </Grid>
      </Grid>

      {openModal && (
        <DoctorModal
          open={openModal}
          onClose={handleModalClose}
          doctorData={data}
          isEditDoctor={isEditDoctor}
        />
      )}
    </>
  );
};

export default DoctorList;
