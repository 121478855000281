import * as React from "react";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { TitleBarList } from "../../utils/constant";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { toast } from "react-toastify";
import api from "../../utils/Api";
import cookie from "react-cookies";
import GroupIcon from "@mui/icons-material/Group";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import LogoutIcon from "@mui/icons-material/Logout";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import MedicationIcon from '@mui/icons-material/Medication';
const drawerWidth = 230;


function Navbar(props) {
  const { window } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = () => {
    try {
      api.post("/admin/logout").then((response) => {
        cookie.remove("token");
        navigate("/");
      });
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(`Error :${error?.response?.data?.message}`);
      }
      toast.error(`Error :${error}`);
    }
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <List sx={{ position: "relative" }}>
      <ListItem key={"brand-logo"} sx={{ justifyContent: "center", mb: 2 }}>
        <img
          style={{ alignItems: "center", mt: 1 }}
          src={require("../../assets/images/logo.png")}
          alt="brand-logo"
          height={70}
          width={80}></img>
      </ListItem>
      <ListItem key={"Data"} disablePadding>
        <ListItemButton
          selected={location.pathname === "/doctors"}
          onClick={() => {
            navigate("/doctors");
          }}>
          <ListItemIcon>
            <LocalHospitalIcon color={location.pathname === "/doctors" ? "primary" : ""} />
          </ListItemIcon>
          <ListItemText primary={<Typography color={location.pathname === "/doctors" && "primary"} variant="body2" sx={{ fontWeight: "bold" }} >{'Doctors'}</Typography>}
          />        </ListItemButton>
      </ListItem>
      <ListItem key={"Patients"} disablePadding>
        <ListItemButton
          selected={location.pathname === "/patients"}
          onClick={() => {
            navigate("/patients");
          }}>
          <ListItemIcon>
            <GroupIcon color={location.pathname === "/patients" ? "primary" : ""} />
          </ListItemIcon>
          <ListItemText primary={<Typography color={location.pathname === "/patients" && "primary"} variant="body2" sx={{ fontWeight: "bold" }} >{'Patients'}</Typography>}
          />        </ListItemButton>
      </ListItem>
      <ListItem key={"Drugs"} disablePadding>
        <ListItemButton
          selected={location.pathname === "/drugs"}
          onClick={() => {
            navigate("/drugs");
          }}>
          <ListItemIcon >
            <VaccinesIcon color={location.pathname === "/drugs" ? "primary" : ""} />
          </ListItemIcon>
          <ListItemText primary={<Typography color={location.pathname === "/drugs" && "primary"} variant="body2" sx={{ fontWeight: "bold" }} >{'Drugs'}</Typography>}
          />
        </ListItemButton>
      </ListItem>
      <ListItem key={"Hospital"} disablePadding>
        <ListItemButton
          selected={location.pathname === "/medicalFaculty"}
          onClick={() => {
            navigate("/medicalFaculty");
          }}>
          <ListItemIcon >
            <MedicationIcon color={location.pathname === "/medicalFaculty" ? "primary" : ""} />
          </ListItemIcon>
          <ListItemText primary={<Typography color={location.pathname === "/medicalFaculty" && "primary"} variant="body2" sx={{ fontWeight: "bold" }} >{'Hospital'}</Typography>}
          />
        </ListItemButton>
      </ListItem>

      <ListItem key={"Doctor Request"} disablePadding>
        <ListItemButton
          selected={location.pathname === "/request"}
          onClick={() => {
            navigate("/request");
          }}>
          <ListItemIcon >
            <MedicationIcon color={location.pathname === "/request" ? "primary" : ""} />
          </ListItemIcon>
          <ListItemText primary={<Typography color={location.pathname === "/request" && "primary"} variant="body2" sx={{ fontWeight: "bold" }} >{'Doctor Request'}</Typography>}
          />
        </ListItemButton>
      </ListItem>
      
      <ListItem
        key={"Logout"}
        disablePadding
        sx={{ width: "230px", position: "fixed", bottom: 0, }}>
        <ListItemButton sx={{
          '&:hover': {
            color: "primary.main",
          }
        }} onClick={() => logout()}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography sx={{
            fontWeight: "bold"
          }} variant="body2" >{'Logout'}</Typography>} />
        </ListItemButton>
      </ListItem>
    </List>
  );
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: {
            lg: `calc(100% - ${drawerWidth}px)`,
            md: `calc(100% - ${drawerWidth}px)`,
            sm: `100%`,
          },
          ml: { lg: `${drawerWidth}px`, md: `${drawerWidth}px}` },
        }}>
        <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              position: "absolute",
              left: 20,
              mr: 2,
              display: { sm: "none" },
              justifyContent: "left",
            }}>
            <MenuIcon />
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }} variant="h6" noWrap component="div">
            {TitleBarList[location?.pathname?.split("/")[1]]}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}>
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open>
        {drawer}
      </Drawer>
    </>
  );
}

Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navbar;
