import { Box, CssBaseline } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
const Dashboard = () => {
  const [searchText] = useState("");
  const [searchId, setSearchId] = useState("");
  const drawerWidth = 230;

  useEffect(() => {
    if (searchText.length) {
      setSearchId("");
    }
  }, [searchText]);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Navbar />
      <Box
        component="main"
        className="dashboard_outlet"
        sx={{
          p: { lg: "1%", md: "3%", sm: "3%" },
          mt: { lg: "4%", md: "4%", sm: "5%", xs: "13%" },
          marginLeft: {
            lg: `${drawerWidth}px`,
            md: `${drawerWidth}px`,
            sm: `${drawerWidth}px`,
          },
          flexGrow: 1,
          width: {
            lg: `calc(100% - ${drawerWidth}px)`,
            sm: `calc(100% - ${drawerWidth}px)`,
            md: `calc(100% - ${drawerWidth}px)`,
          },
        }}>
        <Outlet context={[searchText, searchId]} />
      </Box>
    </Box>
  );
};

export default Dashboard;
