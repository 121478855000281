import { createTheme } from "@mui/material";
const customTheme = createTheme({
  palette: {
    primary: {
      main: "#037180",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#037180",
      contrastText: "#FFFFFF",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    fontFamily: "sofiaPro",
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
      marginBottom:"10px"
    },
    h5: {
      fontWeight: 600,
    },
    
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-weight: 600;
          }
        `,
    },
  },
});

export default customTheme;
