import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Icon,
  InputAdornment,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../utils/Api";
import Listtable from "../../utils/ListTable";
import ModalScreen from "../Modal";
import SearchIcon from "@mui/icons-material/Search";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { CSVLink } from "react-csv";

const DrugList = () => {
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [drugsData, setDrugsData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  const tableHeader = [
    "Company Name",
    "Medicine Name",
    "Generic Name",
    "Drug Type",
    "Doctor Speciality",
  ];
  const keysName = [
    "companyName",
    "medicineName",
    "genericName",
    "type",
    "doctorSpeciality",
  ];

  const csvSampleData = [
    {
      companyName: "Cipla", medicineName: "Sinerest 22", genericName: "Gen 22", type: "CAPSULE", doctorSpeciality: "Aurtho", status: "ACTIVE"
    },
    {
      companyName: "Cipla", medicineName: "Benadrill", genericName: "GEN 3344", type: "SYRUP", doctorSpeciality: "Aurtho", status: "INACTIVE"
    }
  ]

  const DrugListData = (e) => {
    try {
      setBackDropOpen(true);
      const PayLoadDoctorList = {
        ...(e &&
          e.target.value && {
          search: e.target.value,
        }),
        limit: rowsPerPage,
        page: pageNumber + 1,
      };

      api
        .post("/admin/drug-list", PayLoadDoctorList)
        .then((response) => {
          setDrugsData(response?.data?.data?.drugsList);
          setCount(response?.data?.data?.totalCount);
          setBackDropOpen(false);
        })
        .catch((err) => {
          setBackDropOpen(false);
          if (err?.response?.data?.message) {
            toast.error(`Error :${err?.response?.data?.message}`);
          } else {
            toast.error("Drugs Not Found");
          }
        });
    } catch (error) {
      setBackDropOpen(false);
      toast.error("Drugs list error");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPageNumber(0);
  };

  const handleModal = (e) => {
    setData(e);
    setOpenModal(true);
  };
  const handleChange = function (e) {
    try {
      if (e?.target?.files.length > 0) {
        setIsUploading(true);
        let fileData = e?.target?.files[0];
        console.log("File Data", fileData);
        var csvUploadBody = new FormData();
        csvUploadBody.append("drugs", fileData);

        api
          .post("/admin/add-drugs", csvUploadBody, {
            "Content-Type": "multipart/form-data",
          })
          .then(() => {
            toast.success(`Drugs uploaded successfully !`);
            setRowsPerPage(5);
            setPageNumber(0);
            setIsUploading(false);
            DrugListData();
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              toast.error(`Error : ${err?.response?.data?.message}`);
            } else {
              toast.error(`Drugs upload failed !`);
            }
            setIsUploading(false);
          });
        e.target.value = null;
      } else {
        e.target.value = null;
        toast.error(`Selected a file !`);
      }
    } catch (err) {
      console.log("Error", err);
      setIsUploading(false);
      toast.error(`Drugs upload failed !`);
    }
  };

  const handleUploadButtonClick = () => {
    document.getElementById("input-file-upload").click();
  };

  const handleModalClose = (buttonText = "save") => {
    setData({});
    if (buttonText !== "close") {
      DrugListData();
    }
    setOpenModal(false);

  };

  useEffect(() => {
    DrugListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, pageNumber]);

  useEffect(() => {
    DrugListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Grid container alignItems="center">
        <Grid item sm={12} md={12} lg={12} padding={2}>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={1}>
              <Grid item sm={3} md={3}>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={backDropOpen}>
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Stack direction="row-reverse">
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                    sx={{ justifyContent: "right !important" }}
                    type={"text"}
                    variant="outlined"
                    placeholder="Search Name"
                    onKeyPress={(e) => {
                      e.charCode === 13 && DrugListData(e);
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item sm={3} md={3}></Grid>
              <Grid item sm={2} md={2}>
                <CSVLink
                  data={csvSampleData}
                  filename={"drugSample.csv"}
                  className="btn btn-primary"
                  target="_blank"
                >
                  <Button
                    variant="contained"
                    fullWidth>
                    {isUploading ? (
                      <CircularProgress sx={{ color: "white" }} size="1.5rem" />
                    ) : (
                      <>
                        <Icon sx={{ mt: -1, mr: 1 }}>
                          <CloudDownloadIcon />
                        </Icon>
                        Sample CSV
                      </>
                    )}
                  </Button>
                </CSVLink>
              </Grid>
              <Grid item sm={2} md={2}>
                <Button
                  variant="contained"
                  onClick={handleUploadButtonClick}
                  fullWidth>
                  {isUploading ? (
                    <CircularProgress sx={{ color: "white" }} size="1.5rem" />
                  ) : (
                    <>
                      <Icon sx={{ mt: -1, mr: 1 }}>
                        <CloudUploadIcon />
                      </Icon>
                      Upload CSV
                    </>
                  )}
                  <input
                    type="file"
                    accept="text/csv"
                    id="input-file-upload"
                    multiple={false}
                    hidden
                    onChange={handleChange}
                  />
                </Button>
              </Grid>
              <Grid item sm={2} md={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => handleModal({})}>
                  Add Drug
                </Button>
              </Grid>
            </Grid>
          </Paper>

          <Listtable
            tableheader={tableHeader}
            tablebody={drugsData}
            rowsPerPage={rowsPerPage}
            page={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalCount={count}
            dataKeyName={keysName}
            editHandle={handleModal}
            tag="drugs"
          />
        </Grid>
      </Grid>
      {openModal && (
        <ModalScreen
          open={openModal}
          onClose={handleModalClose}
          data={data}
          type="drugs"
        />
      )}
    </>
  );
};

export default DrugList;
