import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import "./Login.css";
import { toast } from "react-toastify";
import api from "../../utils/Api";
import cookie from "react-cookies";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useUserDispatch } from "../../utils/AdminContext";

const Login = () => {
  const [screen, setSecreen] = useState("login");
  const [mobileNumber, setMobileNumber] = useState("");
  const { setIsUserAuthenticated } = useUserDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [otp, setOtp] = useState("");

  const handleOTP = () => {
    if (otp.length < 6) {
      toast.error(`Please Enter a Valid OTP`);
    }
    try {
      const isMobile = !!mobileNumber.includes('@');
      const PayloadOfOtp = {
        ...(isMobile ? {email : mobileNumber} : { phone: mobileNumber }),
        otp: otp,
      };
      api
        .post("/auth/verify-admin", PayloadOfOtp)
        .then(async (response) => {
          cookie.save("token", response.data.token);
          await setIsUserAuthenticated(true);
          navigate("/doctors");
        })
        .catch((err) => {
          toast.error(`Couldn't verify OTP`);
        });
    } catch (error) {
      toast.error(`Error:${error}`);
    }
  
}

  const onPressSignIn = () => {
    if (mobileNumber.length < 10) {
      toast.error(`Please Enter a Valid Mobile Number`);
    } else {
      try {
        const isMobile = !!mobileNumber.includes('@');
        const payload = {
          ...(isMobile ? {email : mobileNumber} : { phone: mobileNumber })
        };
        api
          .post("/auth/admin/request", payload)
          .then((response) => {
            setSecreen("otp");
          })
          .catch((err) => {
            toast.error(err.response.data.error);
          });
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    // if (value.match(/^[0-9]*$/) && value.length <= 10) {
    setMobileNumber(value);
    // }
  };

  return (
    <Container component="main"  sx={{width:'60%'}} >
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid sx={{ m: 1 }}>
          <img
            alt=""
            src={require("../../assets/images/logo.png")}
            height="70"
            width="70"
          />{" "}
        </Grid>
        <Typography sx={{pb:2}} color={'primary'} component="h1" variant="h5">
          {t('WELCOME_BACK')}
        </Typography>
        <Typography sx={{pb:2}} color={'primary'} component="h1" variant="h5">
          {t("SIGNIN")}
        </Typography>
        <Box noValidate sx={{ mt: 1 , minWidth:'40%' ,width:"50%",textAlign:"center" }}>
          {screen === "login" ? (
            <TextField
              margin="normal"
              required
              fullWidth
              id="mobile"
              label={t('MOBILE_NUMBER_EMAIL')}
              name="mobile"
              autoFocus
              onChange={(e) => handleChange(e)}
              value={mobileNumber}
            />
          ) : (
            <MuiOtpInput
              value={otp}
              onChange={(value) => setOtp(value)}
              length={6}
            />
          )}

          {
            screen === "login" ?
            (
              <Button
                onClick={onPressSignIn}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2  }}
              >
                {t("SIGNIN")}
              </Button>
            ):
            (
              <>
                <Button
                  onClick={handleOTP}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3  }}
                >
                  {t("VERIFY_OTP")}
                </Button>
                <Button
                  onClick={onPressSignIn}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2  }}
                >
                  {t("RESEND_OTP")}
                </Button>
              </>
            )
          }
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
