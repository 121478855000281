import {
  Autocomplete,
  Chip,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  FormControl,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Specialities from "../assets/data/specialities.json";
import drugTypes from "../assets/data/drugtypes.json";
import api from "../utils/Api";
import { toast } from "react-toastify";

import DatePicker from "./DatePicker";
import moment from "moment";

const ModalScreen = ({ open, onClose, data, type }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const [_id, setID] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [doctorEmail, setDoctorEmail] = useState("");
  const [doctorPhone, setDoctorPhone] = useState("");
  const [doctorExperience, setDoctorExperience] = useState("");
  const [doctorEducation, setDoctorEducation] = useState("");
  const [specialities, setSpecialities] = useState([]);
  const [doctorStatus, setDoctorStatus] = useState("");

  const [patientName, setPatientName] = useState("");
  const [patientEmail, setPatientEmail] = useState("");
  const [patientPhone, setPatientPhone] = useState("");
  const [patientGender, setPatientGender] = useState("");
  const [patientABHA, setPatientABHA] = useState("");
  const [patientDOB, setPatientDOB] = useState(
    moment(new Date().toDateString())
  );

  const [companyName, setCompanyName] = useState("");
  const [medicineName, setMedicineName] = useState("");
  const [genericName, setGenericName] = useState("");
  const [drugType, setDrugType] = useState("");
  const [doctorSpeciality, setdoctorSpeciality] = useState("");
  const [drugStatus, setDrugStatus] = useState("");
  const [isApiLoading, setIsApiLoading] = useState(false);

  const submitDrugDetails = () => {
    try {
      const drugPayload = {
        companyName,
        medicineName,
        genericName,
        type: drugType,
        doctorSpeciality,
        status: drugStatus,
      };
      if (_id) {
        api
          .post(`/admin/update-drug-details/${_id}`, drugPayload)
          .then(() => {
            setIsApiLoading(false);
            onClose();
            toast.success("Drug Updated Succesfully");
          })
          .catch((err) => {
            setIsApiLoading(false);
            toast.error("Error " + err);
          });
      } else {
        api
          .post(`/admin/add-new-drug`, drugPayload)
          .then(() => {
            setIsApiLoading(false);
            onClose();
            toast.success("Drug Created Succesfully");
          })
          .catch((err) => {
            setIsApiLoading(false);
            onClose("close");
            toast.error("Error " + err);
          });
      }
    } catch (err) {
      setIsApiLoading(false);
      onClose("close");
      toast.error(`Failed to ${_id ? "update" : "add"} drug !`);
    }
  };

  const handleSubmitDoctor = () => {
    try {
      const PayloadDoctor = {
        name: doctorName,
        experience: doctorExperience,
        education: doctorEducation,
        specialities: specialities,
        doctorStatus,
      };
      api
        .post(`/admin/doctor/update/${_id}`, PayloadDoctor)
        .then((res) => {
          onClose();
          toast.success("Doctor Updated Succesfully");
        })
        .catch((err) => {
          toast.error("Error " + err);
        });
    } catch (error) {
      toast.error(`Error ${error}`);
    }
  };
  useEffect(() => {
    if (Object.entries(data).length > 0) {
      if (type === "doctors") {
        setDoctorName(data?.name);
        setDoctorEmail(data?.email);
        setDoctorExperience(data?.experience);
        setDoctorEducation(data?.education);
        setDoctorPhone(data?.phone);
        setSpecialities([...data?.specialities]);
        setDoctorStatus(data?.doctorStatus);
        setID(data?._id);
      } else if (type === "patients") {
        setPatientName(data?.name);
        setPatientEmail(data?.email);
        setPatientDOB(data?.dateOfBirth);
        setPatientGender(data?.gender);
        setPatientPhone(data?.phone);
        setPatientABHA(data?.healthId[0]);
      } else if (type === "drugs") {
        setCompanyName(data?.companyName || "");
        setMedicineName(data?.medicineName || "");
        setID(data?._id || "");
        setGenericName(data?.genericName || "");
        setDrugType(data?.type || "");
        setdoctorSpeciality(data?.doctorSpeciality || "");
        setDrugStatus(data?.status || "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Modal
        open={open}
        onClose={() => onClose('close')}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Grid
            container
            spacing={2}
            display="flex"
            justifyContent="left"
            sx={{ mb: 3 }}>
            <Grid item>
              <Typography variant="h5" component="h5" align="center">
                {type === "doctors" ? "Edit Doctor" : (_id ? "Edit Drug" : "Add Drug")}
              </Typography>
            </Grid>
          </Grid>
          {type === "doctors" && (
            <Grid container spacing={3} alignItems="center">
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  value={doctorName}
                  label="Name"
                  name="name"
                  onChange={(e) => setDoctorName(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Email"
                  name="email"
                  disabled
                  value={doctorEmail}
                  onChange={(e) => setDoctorEmail(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Phone"
                  name="email"
                  disabled
                  onChange={(e) => setDoctorPhone(e.target.value)}
                  value={doctorPhone}
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Experience"
                  size="small"
                  name="experience"
                  value={doctorExperience}
                  onChange={(e) => setDoctorExperience(e.target.value)}
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Education"
                  size="small"
                  name="education"
                  value={doctorEducation}
                  onChange={(e) => setDoctorEducation(e.target.value)}
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <Autocomplete
                  options={Specialities}
                  multiple
                  value={specialities}
                  onChange={(e, newValue) => {
                    setSpecialities([...newValue]);
                  }}
                  getOptionLabel={(option) => `${option}`}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="filled"
                        label={`${option}`}
                        {...getTagProps(option)}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="specialities"
                      label="Specialization"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label-doctor">
                    Doctor Status
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-select-small-label-doctor"
                    id="demo-select-small-deoctor"
                    value={doctorStatus}
                    label="Doctor Status"
                    onChange={(e) => setDoctorStatus(e.target.value)}>
                    <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
                    <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                sm={12}
                lg={12}
                md={12}
                display="flex"
                justifyContent="right">
                <Button variant="contained" onClick={handleSubmitDoctor}>
                  Submit
                </Button>
                <Button
                  sx={{ marginLeft: "5px" }}
                  variant="contained"
                  onClick={() => onClose('close')}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
          {type === "patients" && (
            <Grid container spacing={3} justifyContent="center">
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  value={patientName}
                  onChange={(e) => setPatientName(e.target.value)}
                  label="Name"
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Email"
                  value={patientEmail}
                  onChange={(e) => setPatientEmail(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Phone"
                  value={patientPhone}
                  onChange={(e) => setPatientPhone(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Gender
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    onChange={(e) => setPatientGender(e.target.value)}
                    name="radio-buttons-group">
                    <FormControlLabel
                      value="FEMALE"
                      control={<Radio />}
                      label="Female"
                      checked={patientGender === "FEMALE"}
                    />
                    <FormControlLabel
                      value="MALE"
                      control={<Radio />}
                      label="Male"
                      checked={patientGender === "MALE"}
                    />
                    <FormControlLabel
                      value="OTHER"
                      control={<Radio />}
                      checked={patientGender === "OTHER"}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  disabled
                  type={"text"}
                  label="ABHA Address"
                  size="small"
                  value={""}
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <DatePicker
                  value={patientDOB}
                  onDateChange={(e) => setPatientDOB(e)}
                />
              </Grid>
              <Grid
                item
                sm={12}
                lg={12}
                md={12}
                display="flex"
                justifyContent="right">
                <Button variant="contained">Submit</Button>
              </Grid>
            </Grid>
          )}
          {type === "drugs" && (
            <Grid container spacing={3} justifyContent="center">
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  label="Company Name"
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Medicine Name"
                  value={medicineName}
                  onChange={(e) => setMedicineName(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Generic Name"
                  value={genericName}
                  onChange={(e) => setGenericName(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Drug Type
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={drugType}
                    label="Drug type"
                    onChange={(e) => setDrugType(e.target.value)}>
                    {drugTypes.map((selectedType, index) => (
                      <MenuItem value={selectedType} key={index}>
                        {selectedType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <Autocomplete
                  options={Specialities}
                  value={doctorSpeciality}
                  onChange={(e, newValue) => {
                    setdoctorSpeciality(newValue);
                  }}
                  getOptionLabel={(option) => `${option}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="specialities"
                      label="Specialization"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Drug Status
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={drugStatus}
                    label="Drug Status"
                    size="small"
                    onChange={(e) => setDrugStatus(e.target.value)}>
                    <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
                    <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                sm={12}
                lg={12}
                md={12}
                display="flex"
                justifyContent="right">
                <Button variant="contained" onClick={submitDrugDetails}>
                  Submit
                </Button>
                <Button
                  sx={{ marginLeft: "5px" }}
                  variant="contained"
                  onClick={() => onClose('close')}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ModalScreen;
