import { Route, Routes } from "react-router-dom";
import Login from "./components/SignIn/Login";
import React from "react";
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./components/Dashboard/Dashboard";
import DoctorList from "./components/ManageDoctors/Doctorlist";
import DoctorsRequest from "./components/DoctorsRequest/Doctorlist";
import PatientList from "./components/ManagePatients/Patientslist";
import DrugList from "./components/ManageDrugs/DrugsList";
import HospitalList from "./components/Hospital/MedicalFacultyList";
import "./utils/i18n";
import HospitalDoctors from "./components/Hospital/hospitalDoctors";

function App(props) {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route index exact path="doctors" element={<DoctorList />} />
          <Route exact path="patients" element={<PatientList />} />
          <Route exact path="drugs" element={<DrugList />} />
          <Route exact path="medicalFaculty" element={<HospitalList />} />
          <Route exact path="medicalFaculty/doctors" element={<HospitalDoctors />} />
          <Route index exact path="request" element={<DoctorsRequest />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
